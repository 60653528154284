<template>
    <section id="home" class="section welcome-area bg-inherit d-flex align-items-center h-100vh">
        <div class="container">
            <div class="row align-items-center">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-7 col-lg-6">
                    <div class="welcome-intro">
                        <h1 class="text-warning">Shimmermare</h1>
                        <h3 class="text-white">Denis Pavlov • Software Engineer</h3>
                        <p class="text-white my-4">My primary expertise is development of high-load backend applications on Java/Kotlin + Spring stack. Also, I have a good grasp on making games with C# and Unity, and hands-on experience in so many different things not worth mentioning. Check my LinkedIn (below) for details.
                            <br/>Currently busy working on <a href="https://megaspell.net" class="text-warning">Megaspell</a>, a post-apocalyptic top-down shooter game.</p>
                    </div>
                </div>
                <div class="col-12 col-md-5 mx-auto pt-4 pt-md-0">
                    <!-- Welcome Thumb -->
                    <div class="welcome-thumb">
                        <img src="assets/img/sunset.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutMe'
}
</script>

<style>

</style>