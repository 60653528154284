<template>
    <header class="section header-area">
        <div id="appo-header" class="main-header-area nav-white">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <!-- Logo -->
                    <router-link class="navbar-brand" to="/">
                        <img class="logo" src="assets/img/cutiemark.png" alt="">
                    </router-link>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="appo-menu">
                        <!-- Header Items -->
                        <ul class="navbar-nav header-items ml-auto">
                            <li class="nav-item">
                                <router-link class="nav-link scroll" to="/">Home</router-link>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://megaspell.net">Megaspell</a>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link scroll" to="/4k-pony-project">4K Pony Project</router-link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
export default {}
</script>

<style>

</style>