<template>
    <section class="section about-app-area ptb_180">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <div class="about-text">
                        <!-- Headings -->
                        <div class="headings d-flex align-items-center mb-2">
                            <h2 class="text-capitalize">Contact me</h2>
                        </div>
                        <!-- Tip -->
                        <p class="d-sm-block mb-3">For business inquiries please use Email or LinkedIn.</p>
                        <!-- Contacts -->
                        <div>
                            <h3 class="my-1 text-muted">Email</h3>
                            <a class="text-warning" href="mailto:shimmermare@gmail.com">shimmermare@gmail.com</a>
                        </div>
                        <div>
                            <h3 class="my-1 text-muted">LinkedIn</h3>
                            <a class="text-warning" href="https://www.linkedin.com/in/shimmermare/" target="_blank">linkedin.com/in/shimmermare</a>
                        </div>
                        <div>
                            <h3 class="my-1 text-muted">Twitter</h3>
                            <a class="text-warning" href="https://twitter.com/shimmermare" target="_blank">twitter.com/shimmermare</a>
                        </div>
                        <div>
                            <h3 class="my-1 text-muted">Discord</h3>
                            <a class="text-warning">shimmmermare</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- About Thumb -->
                    <div class="about-thumb text-center d-none d-lg-block">
                        <img src="assets/img/sunset_on_a_cloud.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {}
</script>

<style>

</style>